var generic = generic || {};
var site = site || {};

(function ($) {
  site.globalnav = {
    templateContainer: $('.footer-top-v1'),

    collateMessages: function (messages) {
      if (Array.isArray(messages)) {
        var len = messages.length;
        var tmp = [];

        for (var i = 0; i < len; i++) {
          tmp.push(messages[i].text);
        }

        return tmp.join('<br />');
      }

      return;
    },

    initExtras: function () {
      this
        .templateContainer
        .find('form')
        .on('clickoutside touchstartoutside', function (e) {
          var $me = $(this);

          $me.children('.extra').add($me.children('.form-messages')).slideUp();
          $me.find('.button').css('background-color', '#BDBCBC');
        // $(this).children('.extra').slideUp();
        })
        .children('.extra')
        .each(function () {
          var $self = $(this);
          var $form = $self.parent('form');
          var formHeight = $form.outerHeight(true);

          $self
            .css('bottom', '-' + formHeight + 'px')
            .siblings().find('.form-text').focus(function () {
              $self.add($self.siblings('.form-messages')).slideDown();
              $form.find('.button').css('background-color', '#5DBA98');
            });
        });
    },

    initEmailSignup: function () {
      var self = this;

      // hide the newsletter signup in the footer if they are opted into email promtions
      // but we need to wait until the user data is loaded which is done after all the data is loaded
      $(document).on('user.loaded', function () {
        var user = generic.user.getUser();

        if (user.pc_email_optin == 1) {
          $('.newsletter-signup').hide();
        }
      });

      var emailContainerNode = this.templateContainer.find('.newsletter-signup');

      if (!emailContainerNode) {
        return null;
      }

      var emailFormNode = emailContainerNode.find('form'),
        emailProgressNode = emailContainerNode.find('div.progress'),
        emailSuccessNode = emailContainerNode.find('div.success'),
        emailErrorNode = emailContainerNode.find('div.error');

      emailFormNode.once('newsletter-signup-form').on('submit', function (submitEvt) {
        submitEvt.preventDefault();

        emailSuccessNode.addClass('hidden');
        emailErrorNode.addClass('hidden');
        emailProgressNode.removeClass('hidden');

        // set the ACTION for the email signup based on the whether the PC_EMAIL_PROMOTIONS is checked
        // its not required to sign up so we dont want it set by default
        var action = $("#newsletter-signup input[name='PC_EMAIL_PROMOTIONS']").prop('checked') ? 'set' : 'unset';

        $("#newsletter-signup input[name='ACTION']").val(action);

        // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces        
        var fields = [
          'PC_EMAIL_ADDRESS',
          'PC_EMAIL_PROMOTIONS',
          'PC_EMAIL_PROMOTIONS_PRESENT',
          'ACTION',
          'SMS_PROMOTIONS',
          'SMS_PROMOTIONS_PRESENT',
          'MOBILE_NUMBER',
          'CARRIER_CODE',
          'LAST_SOURCE',
          'LANGUAGE_ID',
          'COUNTRY_ID',
          'LAST_SOURCE',
          'ORIGINAL_SOURCE',
          'redirect_or_text',
          'redirect'
        ];
        var paramObj = {};

        // loop through all the fields and get the values
        $.each(fields, function (index, value) {
          var formField = $('#newsletter-signup input[name=' + value + ']');

          // for the unchecked checkboxes we want to send empty strings to backend for processing
          if (formField.is(':checkbox') && !formField.prop('checked')) {
            paramObj[value] = '';
          } else {
            paramObj[value] = formField.val();
          }
        });

        paramObj['_SUBMIT'] = 'email_sms_signup';

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            emailProgressNode.addClass('hidden');
            var $success = emailSuccessNode.html();

            emailFormNode.addClass('success').html($success);
          },
          onFailure: function (jsonRpcResponse) {
            var messages = self.collateMessages(jsonRpcResponse.getMessages());

            emailProgressNode.addClass('hidden');
            emailErrorNode.empty();
            emailErrorNode.html(messages).removeClass('hidden');
          }
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFooterTopV1 = {
    attach: function (context, settings) {
      site.globalnav.initExtras();
      site.globalnav.initEmailSignup();
    }
  };

  // code to embed wmode in iframe src url
  jQuery('iframe[src]').each(function () {
    var url = jQuery(this).prop('src');

    if (url.search(/\?/) === -1) {
      jQuery(this).prop('src', url + '?wmode=opaque');
    } else {
      jQuery(this).prop('src', url + '&wmode=opaque');
    }
  });
})(jQuery);
